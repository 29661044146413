module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":true,"database":false,"firestore":true,"storage":true,"messaging":false,"functions":true,"performance":false},"credentials":{"apiKey":"AIzaSyCBd5J0oLSl3WN4duuGm_AFZZnQ3Su1OY0","authDomain":"columna-11585.firebaseapp.com","databaseURL":"https://columna-11585.firebaseio.com","projectId":"columna-11585","storageBucket":"columna-11585.appspot.com","messagingSenderId":"75180521431","appId":"1:75180521431:web:d3a2d0fadb64b971c0fd92","measurementId":"G-QQPZNGV36X"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Columna","short_name":"Columna","start_url":"/","background_color":"#666","theme_color":"#666","display":"minimal-ui","icon":"static/ico.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
