// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artech-plaza-js": () => import("./../src/pages/artech-plaza.js" /* webpackChunkName: "component---src-pages-artech-plaza-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-feria-maroma-js": () => import("./../src/pages/feria-maroma.js" /* webpackChunkName: "component---src-pages-feria-maroma-js" */),
  "component---src-pages-gallery-js": () => import("./../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maroma-js": () => import("./../src/pages/maroma.js" /* webpackChunkName: "component---src-pages-maroma-js" */),
  "component---src-pages-muralista-js": () => import("./../src/pages/muralista.js" /* webpackChunkName: "component---src-pages-muralista-js" */),
  "component---src-pages-producto-js": () => import("./../src/pages/producto.js" /* webpackChunkName: "component---src-pages-producto-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

